// ConversationDetail.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const ConversationDetail = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [conversation, setConversation] = useState(null);
    const [failed, setFailed] = useState(false);
    const [link, setLink] = useState(null);

    const navigate = useNavigate();
    const getUserInfo = async (lis) => {
        const updatedMessages = [...lis];
        for(let li = 0; li < lis.length; li++){

            if(updatedMessages[li]["role"] == "user"){
                const idd = updatedMessages[li]["content"].split(":")[0]
                const url = new URL("/getconvos/userinfo", window.location.origin);
                url.searchParams.append('userid', idd);
                const response = await fetch(url);
                if (!response.ok) throw new Error('Failed to fetch results');
                const data = await response.json();
                console.log(data)
                updatedMessages[li]["pfp"] = data["pfp"]
                updatedMessages[li]["tag"] = data["tag"]
            } else if (updatedMessages[li]["role"] == "assistant") {
                updatedMessages[li]["pfp"] = "https://pbs.twimg.com/profile_images/1831505929461968896/CNRmDc4v_normal.jpg"
                updatedMessages[li]["tag"] = "Thee21The"
            }
        }

        return updatedMessages
    }

    useEffect(() => {
        const fetchConversation = async () => {
                const response = await fetch(`/getconvos/conversation/${id}`); // Update this endpoint as needed
                const data = await response.json();
                data["messages"] = await getUserInfo(JSON.parse(data["messages"]))
                setConversation(data);
                if(data["messages"].length % 2 != 0 || JSON.parse(data["replies"]).length != data["messages"].length / 2){
                    setFailed(true)
                }
                if(data["postid"]){
                    setLink("https://x.com/thee21the/status/"+data["postid"])
                } else if (JSON.parse(data["replies"]).length != 0){
                    setLink("https://x.com/thee21the/status/"+JSON.parse(data["replies"])[0])
                } 
                
        };

        fetchConversation();

    }, [id]);

    if (!conversation) {
        return <div></div>; // Handle the initial null state
    }
    return (
    
    <div className="md:flex text-left flex-col items-center space-y-4 text-white">
<div className="flex flex-row justify-between items-center w-full md:max-w-lg">
    <button 
        style={{ backgroundColor: "#d1813b" }} 
        onClick={() => navigate(-1)} 
        className="text-white ml-5 mt-5 mb-2 px-4 py-2 rounded"
    >
        Go Back
    </button>
    {link ? (
        <button 
            style={{ backgroundColor: "#d1813b" }} 
            onClick={() => window.location.href = link} 
            className="text-white mr-5 mt-5 mb-2 px-4 py-2 rounded"
        >
            Show on 𝕏
        </button>
    ) : (
        <button 
            style={{ backgroundColor: "#804f24" }} // Disabled color
            className="text-white mr-5 mt-5 mb-2 px-4 py-2 rounded cursor-not-allowed" // Prevent pointer cursor
            disabled
        >
            Can't find on 𝕏
        </button>
    )}
</div>


    <div className='w-full md:max-w-lg'>
    <h2 className="text-xl md:text-left text-center font-bold">Conversation Details</h2>
    
    <p className="text-left md:block hidden">Conversation ID: {conversation["id"]}</p>
    
    <p className="md:text-left text-center">Created At: {dayjs.utc(conversation["created_at"]).local().format('MMMM D, YYYY [at] h:mm A') }</p>
    <div style={{ display: failed  ? 'block' : 'none' }} className="bg-red-200 border border-yellow-400 md:rounded-lg rounded-none p-4 mt-4">
                <p className="text-red-800 text-center">
                    The bot failed to respond.
                </p>
            </div>
    </div>
    <div className="w-full md:max-w-lg">


        {conversation["messages"].map((msg) => (
            <div 
                className="md:border border-y-2 border-b-0 border-gray-400 md:rounded-lg rounded-none p-4 mb-4 shadow-md flex flex-col space-y-2"
            >
                <div className="flex items-center space-x-4">
                    <img
                        style={{
                            width: '48px',
                            height: '48px',
                            objectFit: 'cover',
                            backgroundColor: "#d1813b",
                            borderRadius: '20%',
                            border: '2px solid #BDBDBD',
                        }}
                        src={msg["pfp"]}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png";
                        }}
                        alt="Profile"
                        loading="lazy"
                    />
                    <h2 className="text-xl font-bold">
                        {msg["tag"] === "$unknown" ? "Unknown user" :
                         msg["tag"] === "$suspended" ? "Suspended user" :
                         "@" + msg["tag"]}
                    </h2>
                </div>
                <p className="text-gray-200">{msg["content"].replace(/^\d+: (@\w+(?:\s+@\w+)*)\s*|^\d+: /, '').trim()}</p>
            </div>
        ))}
    </div>
</div>

    );
};

export default ConversationDetail; // Ensure this line is present for the default export
