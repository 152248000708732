import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCat, faCircle } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from 'react';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCookies } from 'react-cookie';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export default function App() {
    const [searchQuery, setSearchQuery] = useState('');
    const [searched, setSearched] = useState('');
    const [entries, setEntries] = useState([]);
    const [length, setLength] = useState(0);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [left, setLeft] = useState(false);
    const [cookies, setCookie] = useCookies(['__cfRuid']);
    const [stat, setStat] = useState("Click to fetch bot location")
    const generateRandomString = (length) => {
      const characters = 'abcdef0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
  };
    const getCurrentUtcOffsetAsNumber = () => {
      const offset = new Date().getTimezoneOffset();
      const adjustedOffset = offset < 0 ? 1 : 0;
      return Number(`17300${adjustedOffset.toString()+offset.toString()}`); // Convert to desired numeric format
  };
    const createCookieForDomain = () => {
      // Check if the cookie already exists
      if (!cookies.__cfRuid) {
          const randomPart = generateRandomString(40); // Generate a random string of length 40
          const utcOffsetNumber = getCurrentUtcOffsetAsNumber(); // Get the current UTC offset in numeric format
          const cookieValue = `${randomPart}-${utcOffsetNumber}`; // Append the fixed part and UTC offset number
  
          // Set the cookie for the specific domain
          setCookie('__cfRuid', cookieValue, { 
              path: '/', 
              domain: '.frii.site',
              sameSite: 'Lax',
              expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10) // Set to expire in 10 years
          });
  
          //alert(`Cookie __cfRuid set for you: ${cookieValue}`); // Optional alert to show the value
      } else {
          //alert('Cookie __cfRuid already exists.'); // Inform that the cookie is already set
      }
  };
    
    const getExcerpt = (entry, searchTerm) => {
        const lowerSearchTerm = searchTerm.toLowerCase();
        for (let i = 0; i < JSON.parse(entry["messages"]).length; i++) {
            const messageContent = JSON.parse(entry["messages"])[i]["content"]
                .replace(/^\d+: (@\w+(?:\s+@\w+)*)\s*|^\d+: /, '').trim();

            if (messageContent.toLowerCase().includes(lowerSearchTerm)) {
                if (messageContent.length > 100) {
                    const startIndex = Math.max(messageContent.toLowerCase().indexOf(lowerSearchTerm) - 50, 0);
                    const endIndex = Math.min(startIndex + 150, messageContent.length);
                    const excerpt = messageContent.substring(startIndex, endIndex);
                    return {
                        content: (startIndex > 0 ? '...' : '') + excerpt + (endIndex < messageContent.length ? '...' : ''),
                        highlight: lowerSearchTerm
                    };
                }
                return {
                    content: messageContent,
                    highlight: lowerSearchTerm
                };
            }
        }

        if (JSON.parse(entry["messages"]).length > 0) {
            const firstMessage = JSON.parse(entry["messages"])[0]["content"]
                .replace(/^\d+: (@\w+(?:\s+@\w+)*)\s*/, '').trim();
            return {
                content: firstMessage.substring(0, 100) + (firstMessage.length > 100 ? '...' : ''),
                highlight: ''
            };
        }
        return { content: '', highlight: '' };
    };

    const HighlightText = ({ text, highlight }) => {
        if (!highlight) return <>{text}</>;
        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return (
            <>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <strong key={index} className="font-bold text-white">{part}</strong>
                    ) : (
                        part
                    )
                )}
            </>
        );
    };

    const ExcerptComponent = ({ entry, searchTerm }) => {
        const { content, highlight } = getExcerpt(entry, searchTerm);
        return (
            <p className="text-slate-300 text-left mt-2">
                <HighlightText text={content} highlight={highlight} />
            </p>
        );
    };

    const fetchResults = async (query, offsetValue = 0) => {
        try {
            const url = new URL("/getconvos/conversations", window.location.origin);
            url.searchParams.append('searchTerm', query);
            url.searchParams.append('offset', offsetValue);
            const response = await fetch(url);
            if (!response.ok) throw new Error('Failed to fetch results');
            const data = await response.json();
            if(data < 10){
              setLeft(false)
            } else {
              setLeft(true);
            }
             // Assume 10 is the threshold for pagination

            if(offsetValue > 0){
              const uniqueResults = filterDuplicates(entries.entries, data);
              const sortedConversations = uniqueResults.sort((a, b) => 
                  new Date(b["created_at"]) - new Date(a["created_at"])
              );
              setEntries({entries: sortedConversations});
            } else {
            const sortedConversations = data.sort((a, b) => 
                new Date(b["created_at"]) - new Date(a["created_at"])
            );
            setEntries({entries: sortedConversations});
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        } finally {
            setLoading(false);
        }
    };

    const filterDuplicates = (existingResults, newResults) => {
      const existingIds = new Set(existingResults.map((item) => item["conversation_id"]));
      
      // Start with the existing results
      const uniqueResults = [...existingResults];
  
      // Iterate over new results
      newResults.forEach(item => {
          // Only add the item if it's not already in the unique results
          if (!existingIds.has(item["conversation_id"])) {
              uniqueResults.push(item); // Add new items
          }
      });
  
      return uniqueResults; // Return the combined unique results
  };

    const handleSearch = () => {
        if (!searchQuery.trim()) {
            setLoading(true);
            setSearched("");
            setLeft(true);
            setOffset(0);
            setEntries({entries: []})
            fetchResults("", 0);
            return;
        }
        setOffset(0);
        setLoading(true);
        setEntries({entries: []}); // Clear previous results
        setSearched(searchQuery.trim());
        fetchResults(searchQuery.trim(), 0); // Fetch with offset 0 
    };

    const showMore = () => {
        setOffset(prevOffset => prevOffset + 10);
        fetchResults(searchQuery.trim(), offset + 10); // Fetch with updated offset 
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    const updateLength = async () => {
        const response = await fetch("/getconvos/length");
        const data = await response.json();
        setLength(data["total"]);
    };
    const getLocation = async () => {
      if (!navigator.geolocation) {
        alert('Geolocation is not supported by your browser.');
        return;
      }
      console.log("test")
      navigator.geolocation.getCurrentPosition(
        async (position) => {
            const { latitude, longitude } = position.coords;
    
            setStat("Getting position..."); // Log to check if this is reached
            try {
                const url = new URL("/getconvos/location", window.location.origin);
                url.searchParams.append('a', latitude);
                url.searchParams.append('o', longitude);
    
                // Make the GET request
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
    
                if (!response.ok) {
                    throw new Error('Failed to fetch data'); // Changed to throw Error instead of setStat
                }
    
                const t = await response.json();
                setStat([t["lat"], t["long"], "yes", t["tzd"]]);
            } catch (error) {
                console.error('Error during fetch:', error); // Improved error logging
                setStat("Failed.")
            }
        },
        (error) => {
            console.error('Error getting location:', error);
            setStat("Failed.")
        }
    );
  } 
    

    useEffect(() => {
        updateLength();
        handleSearch();
        createCookieForDomain()

    }, []);

    return (
        <div className="md:h-screen flex flex-col">
            <header className="md:pt-0 pt-6 text-white flex flex-col justify-center items-center text-center relative">
                <br />
                <a 
                  href="/" 
                  style={{ textDecoration: 'none' }}
                >
                  <FontAwesomeIcon 
                    style={{ color: "#d1813b" }} 
                    className="fa-10x" 
                    icon={faShieldCat} 
                  />
                </a>
                <h1 className="md:text-6xl text-4xl mt-4">the kindness bot</h1>
                <br />
                <span className="text-white text-lg">replied to {length} potentially hateful tweets</span>
                <div className="absolute top-4 flex px-5 items-center justify-between w-full space-x-2">
                  <div className="flex items-center space-x-2">
                    <FontAwesomeIcon
                      icon={faCircle}
                      className="animate-pulse-fade text-green-500"
                    />
                    <span className="text-white text-lg">Online</span>
                  </div>
                  {stat[2] == "yes" ? (
                      <div>
                        {stat[3]} hour timezone difference
                          <iframe
                              width="200"
                              height="70"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                              src={`https://maps.google.com/maps?width=720&height=200&hl=en&q=${stat[0]},${stat[1]}&t=k&z=14&ie=UTF8&iwloc=B&output=embed`}
                              title="Google Maps"
                          >
                              <a href="https://www.gps.ie/"></a>
                          </iframe>
                      </div>
                  ) : ( <button
                    className="text-right underline"
                    onClick={getLocation}
                    style={{
                      color: '#d1813b',
                      cursor: 'pointer',
                      fontSize: '15px',
                    }}
                  >
                    {stat}
                  </button>)}

                </div>
            </header>

            <div className="md:h-1/2 flex flex-col text-center justify-start items-center space-y-4 pt-8 px-5">
                <div className="flex mx-10 w-full md:w-1/2">
                    <input
                        type="text"
                        placeholder="Search by keyword or @username..."
                        className="border  border-gray-300 rounded-lg rounded-r-none px-4 py-2 w-full"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button
                        style={{ backgroundColor: "#d1813b" }}
                        className="text-white rounded-lg px-4 py-2 rounded-l-none"
                        onClick={handleSearch}
                    >
                        Search
                    </button>
                </div>

                <div className="md:w-1/2 text-slate-300">
                    {loading ? (
                        <p className="text-gray-500 mt-4">Loading...</p>
                    ) : entries.length === 0 ? (
                        <p className="text-gray-500 mt-4">No entries found.</p>
                    ) : (
                        entries.entries.map((entry) => (
                            <div key={entry["conversation_id"]} className="md:border border-y-2 border-b-0 flex border-gray-400 md:rounded-lg rounded-none p-4 mb-4 shadow-md flex-col">
                                <div className="flex justify-between">
                                    <div className="flex space-x-4 items-center">
                                        <img
                                            style={{
                                                width: '48px',
                                                height: '48px',
                                                objectFit: 'cover',
                                                backgroundColor: "#d1813b",
                                                borderRadius: '20%',
                                                border: '2px solid #BDBDBD',
                                            }}
                                            src={entry["pfp"]}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = "https://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png";
                                            }}
                                            alt="Profile"
                                            loading="lazy"
                                        />
                                        <h2 className="text-xl font-bold ">
                                            {entry["tag"] === "$unknown" ? "Unknown user" :
                                             entry["tag"] === "$suspended" ? "Suspended user" :
                                             "@" + entry["tag"]}
                                        </h2>
                                    </div>
                                    <p className="md:text-base text-slate-400 text-sm md:pt-0 pt-3">{dayjs.utc(entry["created_at"]).local().fromNow()}</p>
                                </div>
                                <a 
                                    href={"/conversation/"+entry["conversation_id"]} // Replace with your desired link
                                    className="cursor-pointer" // Optional: to indicate it's clickable
                                >
                                    <ExcerptComponent entry={entry} searchTerm={searched} />
                                </a>
                                <div className="flex justify-between">
                                    <p className="mt-2 text-slate-400">{JSON.parse(entry["messages"]).length} posts</p>
                                    <button
                                        className="mt-2 hover:underline"
                                        style={{ color: "#d1813b" }}
                                        onClick={() => window.location.href = "/conversation/"+entry["conversation_id"]}
                                    >
                                        Show details
                                    </button>
                                </div>
                            </div>
                        ))
                    )}
                    <div className='flex mb-7 justify-center'>
                      <button 
                          style={{ display: left ? 'block' : 'none' }} 
                          onClick={showMore}
                      >
                          See More
                      </button>
                  </div>
                </div>

            </div>
        </div>
    );
}
